import Swal from 'sweetalert2';

export const openCrmWindow = (contactId) => {
  const crmUrl = process.env.REACT_APP_DYNAMICRM + contactId;
  window.open(crmUrl, '_blank');
};

const toggleSiderDisplay = (isMobile, displayStyle) => {
  if (isMobile) {
      const sidebarElement = document.querySelector('.ant-layout-sider');
      if (sidebarElement) {
          sidebarElement.style.display = displayStyle;
      }
  }
};

export const hideSider = (isMobile) => toggleSiderDisplay(isMobile, 'none');
export const showSider = (isMobile) => toggleSiderDisplay(isMobile, 'block');

export const formatPhoneNumber = (phoneNumber) => {
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  const numberWithoutCountryCode = digitsOnly.startsWith('1') ? digitsOnly.slice(1) : digitsOnly;
  const match = numberWithoutCountryCode.match(/(\d{3})(\d{3})(\d{4})/);

  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumber;
};

export const formatDate = (startTime) => {
  const localDate = new Date(startTime).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  });

  return localDate.replace(/\//g, '-');
};

export const formatTime = (startTime) => { 
  try {
  
    const inputTimeZone = 'America/Los_Angeles';
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(`User's time zone: ${userTimeZone}`);
    const dateTimeInInputZone = new Date(startTime);

    // Define the hour adjustment based on the difference between input time zone and user's time zone
    const timeZoneOffset = {
      'America/Denver': 1,
      'America/Chicago': 2,
      'America/New_York': 3
    };

    // Adjust the time if the user's time zone is different from PST
    if (userTimeZone !== inputTimeZone && timeZoneOffset[userTimeZone]) {
      const adjustedTime = new Date(dateTimeInInputZone);
      adjustedTime.setHours(adjustedTime.getHours() + timeZoneOffset[userTimeZone]);
      

      const adjustedFormatter = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });

      // Format the adjusted time
      const formattedTime = adjustedFormatter.format(adjustedTime);
      console.log(`Adjusted time in user's time zone: ${formattedTime}`);
      return formattedTime;
    }

    // If no adjustment is needed, format the time in PST
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: inputTimeZone,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    });

    // Format the time in PST
    const formattedTimeInPST = formatter.format(dateTimeInInputZone);
    return formattedTimeInPST;
  } catch (error) {
    console.error("Error extracting time components:", error);
    return null;
  }
  };

export const getActiveCalls = async () => {
  try {
      const response = await fetch(process.env.REACT_APP_GETACTIVECALLS, {
          headers: {
              "APIKEY": process.env.REACT_APP_CALLAPIKEY,
              "Origin": '*',
              "crossorigin": "use-credentials",
              "X-Requested-With": "XMLHttpRequest"
          },
          method: 'GET'
      });

      if (response.ok) {
          const data = await response.json();
          return data.data;
      } else {
          throw new Error(response.statusText);
      }
  } catch (error) {
      console.error("Error fetching active calls:", error);
      return null;
  }
};

export const getUserCommunities = async () => {
  const storedListString = sessionStorage.getItem('UserCommunities');
  if (!storedListString) {
      console.error("Failed to fetch user communities");
      return null;
  }

  try {
      return JSON.parse(storedListString);
  } catch (error) {
      console.error("Error parsing stored data:", error);
      return null;
  }
};

export function showFailedToSendTranscript() {
  Swal.close();
  Swal.fire({
      title: "Failed to send transcript to C3. Please try again.",
      showClass: {
          popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
      }
  });
}