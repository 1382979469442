
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './assets/Conversation.css';
import MessageBubble from './MessageBubble'
import Dropzone from 'react-dropzone';
import styles from './assets/Conversation.module.css'
import { Button, Form, Icon, Input, message } from "antd";
import ConversationsMessages from "./ConversationsMessages";
import PropTypes from "prop-types";
import Call from './Call';

import ModalComponent from './Modal';
import Swal from 'sweetalert2';
import "animate.css"
import { showFailedToSendTranscript } from './ConversationsUtils';

require('dotenv').config();
const { TextArea } = Input;
class Conversation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newMessage: '',
      conversationProxy: props.conversationProxy,
      messages: [],
      loadingState: 'initializing',
      boundConversations: new Set(),
      conversationSid: '',
      selectedConversationSid: this.props.selectedConversationSid,
      contact_Phone: '',
      ToPhone: '+18042221111',
      transcript: '',
      firstname: '',
      lastname: '',
      email: 'test_email@kbhome.com',
      isRealtor: false,
      friendlyName: props.friendlyName,
      name: "",
      showResults: false,
      showForm: false,
      modalShowToggle: false,
      companyNum: '',
      projectNum: '',
      isLoaded: false,
      contactId: '',
      buttonTexts: [
      ], 
    };
    const Swal = require('sweetalert2');
  }

  handleButtonClick = (index) => {
    if (index >= 0 && index < this.state.buttonTexts.length) {
      this.setState({ newMessage: this.state.buttonTexts[index] });
    }
  }; 

  handleFriendlyName = (authorValue, friendlyNameValue) => {
    this.setState({
      contact_Phone: authorValue,
      friendlyName: friendlyNameValue
    }, () => {   
    });
  }

  loadMessagesFor = (thisConversation) => {
    if (this.state.conversationProxy === thisConversation) {
      thisConversation.getMessages()
        .then(messagePaginator => {
          if (this.state.conversationProxy === thisConversation) {
            this.setState({ messages: messagePaginator.items, loadingState: 'ready' });
          }
        })
        .catch(err => {
          console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
          this.setState({ loadingState: "failed" });
        });
    }
  };

  componentDidMount = () => {
    if (this.state.conversationProxy) {
      this.loadMessagesFor(this.state.conversationProxy);

      if (!this.state.boundConversations.has(this.state.conversationProxy)) {
        let newConversation = this.state.conversationProxy;
        newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
        this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
      }
    }

    this.companyProjectNumberLookup();
    this.getDefaultMessages();
  }

  
  componentDidUpdate = (oldProps, oldState) => {

    if ((this.state.conversationProxy !== oldState.conversationProxy)) {

      if (this.state.conversationProxy.channelState.friendlyName.includes('+')) {

        var friendlyName = this.state.conversationProxy.channelState.friendlyName;
        var fname = (friendlyName).substring(0, (friendlyName).indexOf(' '));
        var lname = (friendlyName).substring((friendlyName).indexOf(' '), (friendlyName).indexOf('_'))
        this.setState({ firstname: fname });
        this.setState({ lastname: lname });
      }
      this.loadMessagesFor(this.state.conversationProxy);
      this.setState({ selectedConversationSid: this.props.selectedConversationSid });
      if (!this.state.boundConversations.has(this.state.conversationProxy)) {
        let newConversation = this.state.conversationProxy;
        newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
        this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
      }

    }
  };

  getDefaultMessages = async () => {
      const response = await fetch(process.env.REACT_APP_GETCONVERSATION_DEFAULTMESSAGES, {
          headers: {
            "APIKEY":  process.env.REACT_APP_SMSAPIKEY,
            "Origin": '*',
            "crossorigin": "use-credentials",
            "credentials": "include"
            , "X-Requested-With": "XMLHttpRequest"
          },
          method: 'GET',
        })
        .then((response) => response.json())
        .then((responseJSON) => {
          if (responseJSON.data) {
            var defaultMessages = [];
            defaultMessages = responseJSON.data;
            this.setState({buttonTexts: defaultMessages});
          }    
        }).catch((error) => {
          console.log(error);
        }); 
  }
  static getDerivedStateFromProps(newProps, oldState) {
    let logic = (oldState.loadingState === 'initializing') || oldState.conversationProxy !== newProps.conversationProxy;
    if (logic) {
      return { loadingState: 'loading messages', conversationProxy: newProps.conversationProxy };
    } else {
      return null;
    }
  }
  messageAdded = (message, targetConversation) => {
    if (targetConversation === this.state.conversationProxy)
      this.setState((prevState, props) => ({
        messages: [...prevState.messages, message]
      }));
  };

  onMessageChanged = event => {
    this.setState({ newMessage: event.target.value });
  };

  sendMessage = event => {
    event.preventDefault();
    const message = this.state.newMessage;
    this.setState({ newMessage: '' });
    this.state.conversationProxy.sendMessage(message);
  };

  onDrop = acceptedFiles => {
    this.state.conversationProxy.sendMessage({ contentType: acceptedFiles[0].type, media: acceptedFiles[0] });
  };

  handleSpamDelete = async (removeConvo) => {
    Swal.fire({
      title: 'Do you want to delete this conversation?',
      confirmButtonText: `Yes`,
      showCloseButton: true,
      customClass: {
        denyButton: 'left-margin'
      },
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
          await this.removeConversation();
          Swal.fire({
            title: "Conversation deleted.",
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          });
        } 
    })
  }

  handleCloseConversation = async (removeConvo) => {
  
    let updatedName = "";
    let customerEmail = "";
    let isRealtor = false;

    var friendly_name = this.state.conversationProxy.channelState.friendlyName;
    var fname = (friendly_name).substring(0, (friendly_name).indexOf(' '));
    var lname = (friendly_name).substring((friendly_name).indexOf(' ') + 1, (friendly_name).indexOf('_'));
      const resp = await this.GetContactId().then(async (resp) => { 
        if (this.state.contactId != "") {
          await this.SendConversationTranscriptWithContactId(removeConvo);
        }
        else {
              Swal.fire({
            
                      title: 'Customer Information:',
                      html: `<p>Update customer's name if not correct
                            <input id="swal-input1" class="swal2-input" value="${fname} ${lname}" style="width: 80%;">
                          </p>
                          <p>Do you want to add an email address?
                            <input id="swal-input2" class="swal2-input" placeholder="Email address" style="width: 80%;">
                          </p>
                          <p style="display: flex;">
                          <input id="swal-input3" type="checkbox" value="0" style="margin-left: 50px;">
                            <span style="width: 70%;">This is a real estate professional?</span>             
                          </p>`,
                      confirmButtonText: 'Submit',
                      showCloseButton: true,
                      customClass: {
                          denyButton: 'left-margin'
                      },
                      showClass: 'animate__animated animate__fadeInDown',
                    hideClass: 'animate__animated animate__fadeOutUp'
                }).then((result) => {
                  if (result.isConfirmed) {
                      updatedName = document.getElementById('swal-input1').value;
                      customerEmail = document.getElementById('swal-input2').value;
                      isRealtor = document.getElementById('swal-input3').checked;
                      this.setState({ firstname: (updatedName).split(' ')[0] }, () => {
                        this.setState({ lastname: (updatedName).split(' ')[1] }, () => {
                            this.setState({ email: customerEmail }, () => {
                                this.setState({ isRealtor: isRealtor }, async () => {
                                  await this.SendConversationTranscript(removeConvo);                               
                                      Swal.fire({
                                        title: "Conversation record sent to C3.",
                                        showClass: {
                                          popup: 'animate__animated animate__fadeInDown'
                                        },
                                        hideClass: {
                                          popup: 'animate__animated animate__fadeOutUp'
                                        }
                                      });                                 
                                  });            
                            });             
                        });     
                    });                          
                  } else if (result.isDenied) {
                    console.log("User clicked cancel");
                  }
              });           
            }
        })
  }
GetContactId = async () => {
    const { contactId } = this.state;
    const response = await fetch(process.env.REACT_APP_GETCONTACTID_URL,
      {
        headers: {
          'Content-Type': 'application/json',
          "X-Requested-With": "XMLHttpRequest"
        },
        method: 'POST',
        body: JSON.stringify({
          contact_phonenumber: this.state.contact_Phone,
          ToPhone: this.state.ToPhone

        })
      })
      .then((response) => response.json())
      .then(responseData => {
        this.setState({ contactId: responseData.contactId });
      }).catch((error) => {
        console.log(error);
        return ""
      });

  }
  companyProjectNumberLookup() {
    var str = this.state.conversationProxy.channelState.friendlyName;
    var company_project_num = str.split('_')[2];
    var company_num = company_project_num.substring(0, 4);
    var project_num = company_project_num.substring(4, 7);

    this.setState({ companyNum: company_num }, (company_num) => {
   
    });
    this.setState({ projectNum: project_num }, (project_num) => {
    
    })
  }
  SendConversationTranscriptWithContactId = async (deleteConversation) => {

    Swal.fire({
      title: 'Processing...',
      html: 'Please wait',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      }
    });

    const { contactId } = this.state;
    const trans = document.getElementById("messages").innerText;
    const response = fetch(process.env.REACT_APP_SENDTRANSCRIPT_URL,
      {
        headers: {
          'Content-Type': 'application/json',
          "X-Requested-With": "XMLHttpRequest"
        },
        method: 'POST',
        body: JSON.stringify({
          contact_phonenumber: this.state.contact_Phone,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          email: this.state.email,
          isrealtor: this.state.isRealtor,
          companyNum: this.state.companyNum,
          projectNum: this.state.projectNum,
          contactId: this.state.contactId,
          transcript: trans
        })
      })
      .then(response => {
        if (response.ok && deleteConversation) {
          Swal.close();
          Swal.fire({
            title: "Transcript sent to C3",
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          });     
          this.removeConversation();
        } else {
         
          showFailedToSendTranscript();
        }
      }).catch(error => {
          showFailedToSendTranscript();
        console.log(error);
      });
 
  }

  SendConversationTranscript(deleteConversation) {

    Swal.fire({
      title: 'Processing...',
      html: 'Please wait',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      }
    });
    const trans = document.getElementById("messages").innerText;

    const response = fetch(process.env.REACT_APP_SENDTRANSCRIPT_URL,
      {
        headers: {
          'Content-Type': 'application/json',
          "X-Requested-With": "XMLHttpRequest"
        },
        method: 'POST',
        body: JSON.stringify({
          contact_phonenumber: this.state.contact_Phone,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          email: this.state.email,
          isrealtor: this.state.isRealtor,
          companyNum: this.state.companyNum,
          projectNum: this.state.projectNum,
          contactId: this.state.contactId,
          transcript: trans
        })
      })
      .then(response => {
        if (response.ok && deleteConversation) {
          Swal.close();
          Swal.fire({
            title: "Transcript sent to C3",
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          });     
          this.removeConversation();
        } else {
          showFailedToSendTranscript();
        }
      }).catch(error => {
        showFailedToSendTranscript();
      });
  }

  removeConversation() {
    const { contact_Phone, selectedConversationSid } = this.state; 
    const response = fetch(process.env.REACT_APP_REMOVECONVO_URL,
      {
        headers: {
          'Content-Type': 'application/json',
          "X-Requested-With": "XMLHttpRequest"
        },
        method: 'POST',
        body: JSON.stringify({
          contact_phonenumber: contact_Phone,
          conversationSid: selectedConversationSid
        })
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render = () => {
    const { buttonTexts } = this.state;
    const buttonsPerRow = 2;
    const denyButton = {
      marginLeft: "10px"

    }
    const { items } = this.state;
    return (
    
      <Dropzone
        onDrop={this.onDrop}
        accept="image/*">
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps()} onClick={() => {}}
            id="OpenChannel"
            style={{ position: "relative", top: 0 }}>
            {isDragActive &&
              <div className={styles.drop}>
                <Icon type={"cloud-upload"}
                  style={{ fontSize: "5em", color: "#fefefe" }} />
                <h3 style={{ color: "#fefefe" }}>Release to Upload</h3>
              </div>
            }
            <div
              className={styles.messages}
              style={{
                filter: `blur(${isDragActive ? 4 : 0}px)`,
              }}
            >
              <input id="files" {...getInputProps()} />
              <div className="conversations-messages">
                <ConversationsMessages
                  identity={this.props.myIdentity}
                  messages={this.state.messages}
                  friendlyName={this.state.friendlyName}
                  onSelect={this.handleFriendlyName} />
              </div>
              <div>
                <div>
                <div className="spam-button">
                    <button className="close_conversation" onClick={() => {
                        this.handleSpamDelete(true);
                      }}>SPAM/DELETE
                    </button>
                  </div>
                  <div className="endchat-button">
                    <button className="close_conversation" onClick={() => {
                        this.handleCloseConversation(true);
                      }}>End Chat and Move to C3
                    </button>
                  </div>
                </div>
                  <div>
                  {buttonTexts && buttonTexts.length > 0 && buttonTexts.map((text, index) => (
                      (index % buttonsPerRow === 0) && (
                        <div className="default-message-buttons" key={index / buttonsPerRow}>
                          <Button className={`default-message button${index + 1}`} onClick={() => this.handleButtonClick(index)}>
                            <span className="default-message-span">{text}</span>
                          </Button>

                          {index + 1 < buttonTexts.length && (
                            <Button className={`default-message button${index + 2}`} onClick={() => this.handleButtonClick(index + 1)}>
                              <span className="default-message-span">{buttonTexts[index + 1]}</span>
                            </Button>
                          )}
                        </div>
                      )
                    ))}
                  </div>
                <Form onSubmit={this.sendMessage}>
                  <Input.Group compact={false} style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                    <Input
                      style={{ flexBasis: "100%" }}
                      placeholder={"Type your message here..."}
                      type={"text"}
                      name={"message"}
                      id={styles['type-a-message']}
                      autoComplete={"off"}
                      disabled={this.state.loadingState !== 'ready'}
                      onChange={this.onMessageChanged}
                      value={this.state.newMessage}
                    />
                    <Button className="send-message-button" htmlType="submit" type={"submit"}>Send</Button>
                  </Input.Group>
                </Form>
              </div>
            </div>
          </div>
        )}
      </Dropzone>


    );
  }
}

Conversation.propTypes = { myIdentity: PropTypes.string.isRequired };


export default Conversation;