import React, { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph } from "./graph";
import Button from "react-bootstrap/Button";
import "./styles/App.css";

import ConversationsApp from './ConversationsApp';
import './assets/App.css';
import 'antd/dist/antd.css';



/**********************************************************/

/* const express = require('express')
const app = express()
const port = 3000
app.get('/', (req, res) => {
    res.send('Hello World!');
    alert('New Message');
  })
  
  app.listen(port, () => {
    console.log(`Example app listening at http://localhost:${port}`)
  })
 */


/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
    const { instance, accounts} = useMsal();
    const [graphData, setGraphData] = useState(null);

    /* function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken).then(response => setGraphData(response));
        });
    } */
    localStorage.setItem("name", accounts[0].username);
   
    return (
        
        <>    
             <h5 style={{marginTop:'-37px'}} className="card-title">Welcome {accounts[0].name}</h5> 
          {/*      <h5 className="card-title">Welcome {accounts[0].username}</h5>  */}
     
            {graphData ? 
                <ProfileData graphData={graphData} />
                :
                <div></div>
           /*      <Button variant="secondary" onClick={RequestProfileData}>Request Profile Information</Button> */
            }
        </>
    );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {  
    
    return (
        
        <div className="App">
            <AuthenticatedTemplate>
            <ProfileContent /> 
              <ConversationsApp />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title"></h5>
            </UnauthenticatedTemplate>
        </div>
    );
};


 export default function MyApp() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
} 

