import React from 'react';
import ReactDOM from 'react-dom';
import './assets/style.css';
/* import App from './App'; */
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';  




import "./styles/index.css";
import App from "./App.jsx";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";


//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
 const msalInstance = new PublicClientApplication(msalConfig);

 /**
  * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
  */
 ReactDOM.render(
     <React.StrictMode>
         <MsalProvider instance={msalInstance}>
             <App />
         </MsalProvider>
     </React.StrictMode>,
     document.getElementById("root")
 );
 