import React from "react";
import { Component } from 'react';
import { Alert, Badge, Icon, Layout, Spin, Typography } from "antd";
import { Client as ConversationsClient } from "@twilio/conversations";



import "./assets/Conversation.css";
import "./assets/ConversationSection.css";
/* import { ReactComponent as Logo } from "./assets/twilio-mark-red.svg";  */
import { ReactComponent as Logo } from "./assets/KB_Logo.svg";
import audioFile from "./audio/elegant-notification-sound.mp3";


import Conversation from "./Conversation";
import LoginPage from "./LoginPage";
import { ConversationsList } from "./ConversationsList";
import { HeaderItem } from "./HeaderItem";
import "./assets/style.css";
import Swal from 'sweetalert2';
import "animate.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { number } from "prop-types";
import { isMobile } from "react-device-detect";
import Button from "react-bootstrap/Button";
import Call from './Call';

import { hideSider, openCrmWindow } from './ConversationsUtils';

require('dotenv').config();
const { Content, Sider, Header } = Layout;
const { Text } = Typography;

class ConversationsApp extends React.Component {
  constructor(props) {
    super(props);
    const intervalID = '';
    const name = localStorage.getItem("name") || "";
    const loggedIn = name !== "";

    this.state = {
      name,
      loggedIn,
      token: null,
      statusString: null,
      conversationsReady: false,
      conversations: [],
      selectedConversationSid: null,
      newMessage: "",

      participantPhone: "",
      tokenExp: 1625103620,
      currentToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2E1N2NmOTcxOGJhM2Q1MjI5MGNlZmU3MmVlNGU2NDc0LTE2MjUxMDAwMjAiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJrYmhvbWUiLCJjaGF0Ijp7InNlcnZpY2Vfc2lkIjoiSVM2ZWM0MDQ4NDc0NmU0MzBiYmFjNTRhZDk4NDkyNzE1ZCJ9fSwiaWF0IjoxNjI1MTAwMDIwLCJleHAiOjE2MjUxMDM2MjAsImlzcyI6IlNLYTU3Y2Y5NzE4YmEzZDUyMjkwY2VmZTcyZWU0ZTY0NzQiLCJzdWIiOiJBQzdkODM3MjUxYzFlMjhjMTRhNTkxMjYyODNkZDlhY2ViIn0.h45F_NuHR_XAEjWJ7PSNvpM9vORFESjiw8ExRxTbm1c"
      ,
      friendlyName: null,
      comm_id: [],
      userName: '',
      startOutboundConversation: false,

      toPhone: "", //Twilio phone
      fromPhone: "",  //customer phone
      customerName: "",
      messageBody: "Greetings! Outbound Conversation Started",
      companyProjectNum: "",
      loginUser: "",
      communityList: {},
      communityName: "",
      isUpdated: true,
      newListItemOpened: false,
      newMessageConversationSid: '',
      newMessageConvoSid: [],
      sidTobeRemoved: '',
      time: new Date().getTime(),
      isMobile: false,
      navWidth: '326px',
      text: ">",
      isCollapsed: true,
      contactPhone: '',
      priorConversation: false,

      id:'', 
      firstName:'', 
      lastName:'', 
      fromPhone:'', 
      contactId:'',
      startTime: '',
      chk: '',
      
      isCallTrackingEnabled: false
      
    };
  }
  componentDidMount = () => {
    this.setState({ loginUser: localStorage.getItem('name') });
    if (this.state.loggedIn) {
      this.getToken();
      this.setState({ statusString: "Fetching credentials…" });
    }
    this.screenTest(window.matchMedia('(max-width: 768px)'));

    var mediaQueryList = window.matchMedia('(max-width: 768px)');
    mediaQueryList.addEventListener('change', this.screenTest);

  };

  screenTest = (e) => {
    if (e.matches) {
      this.setState({ isMobile: true }, (isMobile) => {
      });
      this.setState({ navWidth: '102%' }, (navWidth) => {
      });
    } else {
      this.setState({ isMobile: false }, (isMobile) => {
      });
      this.setState({ navWidth: '326px' }, (navWidth) => {
      });

    }
  }
  openNav = () => {
    if (this.state.text == ">") {

      this.setState({ isCollapsed: false });
      this.setState({ text: '<' });
      this.setState({ navWidth: '0px' });

    }
    else {
      this.setState({ isCollapsed: true });
      this.setState({ text: '>' });
      this.setState({ navWidth: '102%' });
    }
  }

  logIn = (name) => {
    name = "kbhome";
    if (name !== "") {
      localStorage.setItem("name", name);
      this.setState({ name, loggedIn: true }, this.getToken);
    }
  };

  logOut = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      name: "",
      loggedIn: false,
      token: "",
      conversationsReady: false,
      messages: [],
      newMessage: "",
      conversations: [],
      override: "",
      numberOfConversations: [],
      displayCallContent: false,
      showChildComponent: false
    });

    localStorage.removeItem("name");
    this.conversationsClient.shutdown();
  };

  getToken = () => {
    var myToken = "";
    var currentTimeInSeconds = Math.floor(Date.now() / 1000);
    if (currentTimeInSeconds >= (this.state.tokenExp - 100)) {

      const response = fetch(process.env.REACT_APP_ACCESSTOKEN_URL,
        {
          headers:
          {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            "X-Requested-With": "XMLHttpRequest"
          },
          method: 'POST'
        }
      )
        .then(response => response.json())
        .then(data => {
          for (const [key, value] of Object.entries(data)) {
            if (key == "token") {
              this.setState({ currentToken: value },
                function () {
                  myToken = this.state.currentToken;
                  this.setState({ token: myToken }, this.initConversations);
                }
              );
            }
            if (key == "expTime") {
              this.setState({ tokenExp: value },
                function () {
                }
              );
            }
          }
        })
    }
    else {
      myToken = this.state.currentToken;
      this.setState({ token: myToken }, this.initConversations);
    }
  };

  initConversations = async () => {
    window.conversationsClient = ConversationsClient;
    this.conversationsClient = await ConversationsClient.create(this.state.token);
    this.setState({ statusString: "Connecting to Twilio…" });
    this.conversationsClient.on("connectionStateChanged", (state) => {
      if (state === "connecting")
        this.setState({
          statusString: "Connecting to Twilio…",
          status: "default"
        });
      if (state === "connected") {
        this.setState({
          statusString: "You are connected.",
          status: "success"
        });
      }
      if (state === "disconnecting")
        this.setState({
          statusString: "Disconnecting from Twilio…",
          conversationsReady: false,
          status: "default"
        });
      if (state === "disconnected")
        this.setState({
          statusString: "Disconnected.",
          conversationsReady: false,
          status: "warning"
        });
      if (state === "denied")
        this.setState({
          statusString: "Failed to connect.",
          conversationsReady: false,
          status: "error"
        });
    });
    const numberOfCons = [];
    var arrayConversation = [];
    this.conversationsClient.on("conversationJoined", async (conversation) => {
      conversation.on('messageAdded', m => this.messageAdded(m, conversation));
      arrayConversation.push(conversation);

      const channelFriendlyName = conversation.channelState.friendlyName;
      const smsPhoneNumber = channelFriendlyName.split('_')[1];
      const allPhoneNumberAssociatedWithLoginUser = [];
      Object.entries(this.state.communityList).map(([key, value]) => (allPhoneNumberAssociatedWithLoginUser.push({ value })));

      for (const smsPhone of allPhoneNumberAssociatedWithLoginUser) {
        if (smsPhone.value === smsPhoneNumber) {
          this.state.conversations.push(conversation);
        }
      } 

      this.setState({ communityName: this.state.communityName });
      this.setState({ conversations: this.state.conversations });
    });

    this.conversationsClient.on("conversationLeft", (thisConversation) => {
      this.setState({
        conversations: [...this.state.conversations.filter((it) => it !== thisConversation)]
      });
    });
  };

  messageAdded = (message, targetConversation) => {
    const participant = message.state.author;
    const smsPhoneNum = targetConversation.channelState.friendlyName.split('_')[1];
    const smsPhoneArray = [];
    Object.entries(this.state.communityList).map(([key, value]) => (smsPhoneArray.push({ value })));
    for (const smsPhone of smsPhoneArray) {
      if (smsPhone.value === smsPhoneNum && participant != "kbhome") {
        this.playSound();
        continue;
      }

    }

    const { newMessageConvoSid } = this.state;
    this.setState({ newMessageConversationSid: targetConversation.sid });
    newMessageConvoSid.push(targetConversation.sid);
    this.setState({ conversations: this.state.conversations });
  };

  getUserCommunityList = async () => {
    const { loginUser } = this.state;
    const LoginUser = loginUser;
    const response = await fetch(process.env.REACT_APP_GETUSER_COMMUNITYLIST_URL + LoginUser, {
      headers: {
        "APIKEY":  process.env.REACT_APP_SMSAPIKEY,
        "Origin": '*',
        "crossorigin": "use-credentials",
        "credentials": "include"
        , "X-Requested-With": "XMLHttpRequest"
      },
      method: 'GET'
    }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    }).then((responseJSON) => {
      let commmunityIdList = {};
      responseJSON.Data.forEach((element, index, array) => {
       commmunityIdList[element.SMSPhoneNumber] = element.SMSPhoneNumber;
      });

      const CallTrackingEnabled = responseJSON.Data.some(item => item.IsCallTrackingEnabled === true);
          if (CallTrackingEnabled) {
            this.setState({ isCallTrackingEnabled: true }, () => {
              console.log("Calling Tracking Enabled");          
            })
          }
             
      this.setState({ communityList: commmunityIdList }, () => {
        const valuesArray = Object.values(commmunityIdList);
        sessionStorage.setItem('UserCommunities', JSON.stringify(valuesArray)); 
      })
    }).catch((error) => {
    });
  }

  componentWillMount = async () => {
    this.setState({ loginUser: localStorage.getItem('name') }, () =>{
     this.getUserCommunityList();
     //this.getCallTrackingEnableFlag();
     });
    clearTimeout(this.intervalID); 
    this.setState({fetchingData: false});   
  }
  playSound = () => {
    let filePath = audioFile;
    const audio = new Audio(filePath);
    audio.play(); 
  }

  getConversationMessages = async (selectedConversationSid) => {
    const response = await fetch(process.env.REACT_APP_GETCONVERSATION_MESSAGES+selectedConversationSid+"/Messages",  
    {
        headers: {

          'Authorization': process.env.REACT_APP_GETMESSAGES
        },
        method: 'GET',
       
      })
      .then((response) => response.json())
      .then(responseData => {

        const messagesArray = responseData.messages;
        const messageAtIndex0 = messagesArray.find(message => message.index === 0);
        const authorAtIndex0 = messageAtIndex0 ? messageAtIndex0.author : null;

        console.log(authorAtIndex0);
        if(authorAtIndex0) {this.setState({contactPhone: authorAtIndex0
          }, () => {
            this.getLeadProvider();
          });
        }    
      }).catch((error) => {
        console.log(error);
        return ""
      })

  }
   getLeadProvider = async () => {
    console.log("contact phone: "+this.state.contactPhone);     
      const response = await fetch(process.env.REACT_APP_GETLEADPROVIDER_URL,
        {
          headers: {
            'Content-Type': 'application/json',
            "X-Requested-With": "XMLHttpRequest"
          },
          method: 'POST',
          body: JSON.stringify({
            contact_phonenumber: this.state.contactPhone,
          })
        })
        .then((response) => response.json())
        .then(responseData => {
        
          if(responseData.leadProvider == "SMS") {
            this.setState({
              priorConversation: true,
              contactId: responseData.contactId
            }, () => {
              console.log("priorConversation: "+this.state.priorConversation);             
            });
          }
          else {
            this.setState({
              priorConversation: false,
              contactId: responseData.contactId
            }, () => {
            });
          }
          
        }).catch((error) => {
          console.log(error);
          return ""
        })
                    
 };
 handleOpenCrm = (contactId) => {
  openCrmWindow(contactId);
}

handleCallClick = (displayCallContent, id, firstName, 
  lastName, fromPhone, contactId,
  startTime, is_mobile ) => {
              this.setState({
                id: id,
                firstName: firstName,
                lastName: lastName,
                fromPhone: fromPhone,
                contactId: contactId,
                startTime: startTime,
                displayCallContent: displayCallContent,
                is_mobile: this.state.isMobile                            
             }, () => {});
             hideSider(this.state.isMobile);
            };

  render() {
    const { isCollapsed, text, isMobile, conversations, selectedConversationSid, status, friendlyName, 
      communityList, newListItemOpened, communityName, newMessageConversationSid, newMessageConvoSid,  
      priorConversation, showChildComponent}  = this.state;
    const selectedConversation = conversations.find((it) => it.sid === selectedConversationSid);
    let conversationContent;
    let callContent = <Call id={this.state.id} 
    firstName={this.state.firstName} 
    lastName={this.state.lastName} 
    fromPhone={this.state.fromPhone}
    contactId={this.state.contactId}
    startTime={this.state.startTime}   
    is_mobile={this.state.isMobile}                    
    />
    
    if (selectedConversation) {
      conversationContent = (
        <Conversation
          conversationProxy={selectedConversation}
          myIdentity={this.state.name}
          selectedConversationSid={this.state.selectedConversationSid}
          friendlyName={friendlyName}
          communityList={communityList}
        />
      );
    } else if (status !== "success") {
      conversationContent = "Loading your conversation!";
    } else {
      conversationContent = "";
    }

    if (this.state.loggedIn) {
      return (
        <>
          
          <div className="conversations-window-wrapper">
            <Layout className="conversations-window-container">
              <Header className="conversations-header">
                <div className="conversations-header-div1">
                  <HeaderItem className="conversations-header-item1">
                    {<Logo />}
                  </HeaderItem>
                  <HeaderItem>
                    <Text className="conversations-headeritem-text1">
                    Text & Calls
                    </Text>
                  </HeaderItem>
                </div>
                {(isMobile == false) &&
                  (<div className="conversations-header-div2">
                    <HeaderItem>
                      <Text className="conversations-headeritem-text2">
                      {!this.state.displayCallContent && selectedConversation  && (
                            selectedConversation.friendlyName.includes("C3") ? (
                              <>
                                 <a onClick={() => this.handleOpenCrm(this.state.contactId)} className="c3-leadprovider-customer-name">
                                  {(selectedConversation.friendlyName).split("_")[0] + " " + (selectedConversation.friendlyName).split("_")[4]}
                                </a>
                                {priorConversation && <span className="c3-leadprovider-message"> *Prior conversations exist in C3.</span>}
                              </>
                            ) : (
                              (selectedConversation.friendlyName).split("_")[0] + " " + (selectedConversation.friendlyName).split("_")[4]
                            )
                          )}                  
                      </Text>
                      
                    </HeaderItem>
                    <HeaderItem className="conversations-header-item2">
                    </HeaderItem>
                  </div>)
                }
              </Header>
              <div style={{ borderBottom: '.5px solid gray' }}>
                {(isMobile == true && isCollapsed == false) ?
                  (<div><span> <Button className="button-back" size="md" onClick={this.openNav}
                  ><span style={{ fontSize: '20px' }}>{text}</span></Button></span>
                    <span className="conversations-convoname">
                      {selectedConversation && ((selectedConversation.friendlyName).split("_")[0] + " " + (selectedConversation.friendlyName).split("_")[4])}</span>
                  </div>
                  ) : ("")
                }
              </div>
              {isMobile == true ?
                (<Layout>
                  <Sider theme={"light"} width={this.state.navWidth} style={{ fontSize: '20px' }}>
                    <ConversationsList
                      conversations={conversations}
                      selectedConversationSid={selectedConversationSid}
                      communityList={communityList}
                      newMessageConversationSid={newMessageConversationSid}
                      newMessageConvoSid={newMessageConvoSid}
                      communityName={this.state.communityName}
                      isMobile={this.state.isMobile}
                      navWidth={this.state.navWidth}
                      isCallTrackingEnabled={this.state.isCallTrackingEnabled}
                      onConversationClick={(item) => {
                        this.setState({ selectedConversationSid: item.sid }, () =>
                          this.setState({ selectedConversationSid: this.state.selectedConversationSid }),
                          this.setState({ friendlyName: item.friendlyName }),
                          this.setState({ displayCallContent: false }),
                          this.setState({ newMessageConvoSid: newMessageConvoSid.filter(i => i !== item.sid) }),
                        );
                         this.openNav();
                      }}
                      onCallClick={this.handleCallClick}  
                    />
                  </Sider>

                  <Content className="conversation-section">
                  <div id="SelectedConversation">{this.state.displayCallContent ? callContent : conversationContent}</div>
                  </Content>
                </Layout>
                ) : (<Layout>
                  <Sider theme={"light"} width={this.state.navWidth}>
                    <ConversationsList
                      conversations={conversations}
                      selectedConversationSid={selectedConversationSid}
                      communityList={communityList}
                      newMessageConversationSid={newMessageConversationSid}
                      newMessageConvoSid={newMessageConvoSid}
                      communityName={this.state.communityName}
                      isMobile={this.state.isMobile}
                      navWidth={this.state.navWidth}
                      isCallTrackingEnabled={this.state.isCallTrackingEnabled}
                      onConversationClick={(item) => {
                        this.setState({ selectedConversationSid: item.sid }, () =>
                          this.setState({ selectedConversationSid: this.state.selectedConversationSid }),
                          this.setState({ friendlyName: item.friendlyName }),
                          this.setState({ displayCallContent: false }),
                          this.setState({ newMessageConvoSid: newMessageConvoSid.filter(i => i !== item.sid) }),  
                        );
                        this.getConversationMessages(item.sid);
                      }}

                      onCallClick={this.handleCallClick}  
                    />
                  </Sider>

                  <Content className="conversation-section">
                  <div id="SelectedConversation">{this.state.displayCallContent ? callContent : conversationContent}</div>
                  </Content>
                </Layout>)
              }
            </Layout>
          </div>
        </>
      );
    }
    return <LoginPage onSubmit={this.logIn} />
  }
}

export default ConversationsApp;
