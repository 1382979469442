import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import "animate.css";
import { Button, Form, Icon, Input, message } from "antd";
import { formatDate, formatTime, formatPhoneNum } from './ConversationsUtils';

import { visibleGridColumnsLengthSelector } from '@material-ui/data-grid';
import { showSider } from './ConversationsUtils';
import { getActiveCalls, getUserCommunities, openCrmWindow } from './ConversationsUtils';
import { showFailedToSendTranscript } from './ConversationsUtils';

class Call extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endcall: true,
      contactId: '',
      showC3ContactLink: false,
      communityList : this.props,
      firstName: '',
      lastName: '',
      sendToDynamics: false,
      textareaContent: 'CALL NOTES FROM OSC*** ',
      note: '',
      updatedFirstName: '',
      updatedLastName: '',
      chk: '',
      email: '',
      isRealtor: false,
      fromPhone: this.props

    };

    this.handleSpamDelete = this.handleSpamDelete.bind(this);
    this.handleEndCall = this.handleEndCall.bind(this);
  }
  
  componentDidMount = () => {
    var communityList;
    communityList = this.props.communityList;
  };

   handleTextareaChange = (event) => {
    this.setState({textareaContent: (event.target.value)})
  };

  handleOpenCrm = (contactId) => {
    openCrmWindow(contactId);
  }
  handleBackButtonClick = () => {
    showSider(this.props.is_mobile);
  }

  handleSpamDelete = async (id) =>  {
  try {
          var Guid = id.id; 
           /* this.state.sendToDynamics = false;    
           console.log("sendToDynamics:"+ this.state.sendToDynamics);   */ 
     
          Swal.fire({
            title: 'Do you want to delete this call record?',
            confirmButtonText: `Yes`,
            showCloseButton: true,
            customClass: {
              denyButton: 'left-margin'
            },
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.setState({ sendToDynamics: false },  () => {
                console.log("sendToDynamics:"+ this.state.sendToDynamics);
                 this.UpdateCall(Guid);   
              });
                             
              } 
          }) 
    }catch (error) {
      console.error('Update failed:', error);
    } 
  }
  UpdateCall = async (Guid) => {
    Swal.fire({
      title: 'Processing...',
      html: 'Please wait',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      }
    });
        const response = await fetch(process.env.REACT_APP_UPDATECALL, {
          headers: {
            "APIKEY":  process.env.REACT_APP_CALLAPIKEY,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
          method: 'POST',
          body: JSON.stringify({
            id: Guid,
            CreateLead: this.state.sendToDynamics,
            Note: this.state.textareaContent,
            FirstName: this.state.firstName !== "" ? this.state.firstName  : this.props.firstName,
            LastName: this.state.lastName !== "" ? this.state.lastName  : this.props.lastName,
            Email: this.state.email,
            FromPhone: this.props.fromPhone,
            IsRealEstateProfessional: this.state.isRealtor,
          }),
        }).then(response => {  
        if (response.ok) {
          Swal.close();
          let message = "Call record sent to C3";
          if(!this.state.sendToDynamics) {
            message = "Record has been deleted";
          }
          Swal.fire({
            title: message,
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          }).then(() => {
             window.location.reload();
          }); 
        } else {
          showFailedToSendTranscript();
          console.error('Failed to update call:', response.status);
        }
      }).catch(error => {
        showFailedToSendTranscript();
      console.log(error);
    });             
  }

  
  handleEndCall = async (id) => {

    const { contactId, phone } = this.props;
    let Guid = id.id; 
    let updatedName = "";
    let customerEmail = "";
    let isRealtor = false;

    if ( contactId) {
      this.setState({ firstName: this.props.firstName }, () => {
        this.setState({ lastName: this.props.lastName }, () => {
          this.setState({ sendToDynamics: true }, () => {
            this.UpdateCall(Guid);
          });
        });
      });
      } else {
        Swal.fire({
                      title: 'Customer Information:',                
                      html: `<br>
                            <p><b>Update customer's name if not correct</b>
                              <input id="swal-input1" class="swal2-input" value="${this.props.firstName } ${this.props.lastName}" style="width: 80%;">
                            </p>
                            <br>
                            <p><b>Do you want to add an email address?</b>
                              <input id="swal-input2" class="swal2-input" placeholder="Email address" style="width: 80%;">
                            </p>
                            <br>
                            <p style="display: flex;">
                            <input id="swal-input3" type="checkbox" value="0" style="margin-left: 50px;">
                              <span style="width: 70%;"><b>This is a real estate professional?</b></span>             
                            </p>`,
                      confirmButtonText: 'Submit',
                      showCloseButton: true,
                      customClass: {denyButton: 'left-margin'},
                      showClass: 'animate__animated animate__fadeInDown',
                      hideClass: 'animate__animated animate__fadeOutUp'
                  }).then((result) => {
                      if (result.isConfirmed) {
                          updatedName = document.getElementById('swal-input1').value;
                          customerEmail = document.getElementById('swal-input2').value;
                          isRealtor = document.getElementById('swal-input3').checked;
                  
                          this.setState({ firstName: (updatedName).split(' ')[0] }, () => {
                              this.setState({ lastName: (updatedName).split(' ')[1] }, () => {
                                  this.setState({ email: customerEmail }, () => {
                                      this.setState({ isRealtor: isRealtor }, () => {
                                         this.setState({ sendToDynamics: true }, () => {                                       
                                             this.UpdateCall(Guid);
                                        });
                                      });
                                  });
                              });
                          });                       
                      }                     
                      else if (result.isDenied) {
                          console.log("User clicked cancel");
                      }
                  });
    }


  }
 
  render() {
    const { id, firstName, lastName, fromPhone, contactId, startTime, is_mobile } = this.props;
    const formattedDate = formatDate(startTime);
    const formattedTime  = formatTime(startTime);  
    return ( 
     <>  
     {is_mobile == true ? (   
      <div> 
        <button type="button" class="button-back btn btn-primary btn-md"
        onClick={() => this.handleBackButtonClick()}>
            <span style={{fontSize: '20px'}}>&lt;</span>
        </button>
          
         <div className="call-outer-container" style={{
          border: '2px solid #ccc', 
          borderRadius: '8px', 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '43vh', 
          width: '95%', 
          margin: 'auto', 
          marginTop: '15%', 
          padding: '20px', position: 'relative'
          }}> 
          <div style={{marginBottom: '15%', width: '90%'}}>    
             <table className="call-table" 
                 style={{ 
                 width: '100%', 
                 border: '2px solid #ccc', 
                 borderCollapse: 'collapse',
                 backgroundColor: 'lightgray',         
               }}>
               <tbody>
                 <tr>
                 <td className="call-contact-link" style={{ fontWeight: 'bold', textAlign: 'left', backgroundColor: 'lightgray' }}>
                     {contactId ? (
                       <a onClick={() => this.handleOpenCrm(contactId)} className="c3-leadprovider-customer-name">
                         {firstName} {lastName} C3
                       </a>
                     ) : (
                       `${firstName} ${lastName}`
                     )}
                     <br />
                     <span>{fromPhone}</span>
                     <span className="call-datetime-span" style={{
                       fontSize: '12px',
                       fontStyle: 'italic',
                       marginLeft: '12px',
                       position: 'relative',
                       marginBottom: '10px'
                     }}> {formattedDate} {formattedTime}
                     </span>
                 </td>
                 </tr>          
               </tbody>
             </table>      
             <textarea className="call-textarea"
               style={{
                 width: '100%',
                 height: '180px',
                 padding: '10px',
                 borderRadius: '4px',
                 border: '1px solid #ccc',
                 boxSizing: 'border-box'
               }}
               value={this.state.textareaContent}
               onChange={this.handleTextareaChange}
               
             >CALL NOTES FROM OSC*** </textarea>
             <div className="call-spamdelete-move-toC3-div" style={{position: 'absolute', marginBottom: '10%', display: 'flex', alignItems: 'center'}}>
               <div onClick={() => {this.handleSpamDelete({id});}}>
               <button className="call-spamdelete" style={{
                     backgroundColor: '#6E6259',
                     color: 'white',
                     padding: '5px 15px',
                     fontSize: '16px',
                     borderRadius: '15px',
                     marginTop: '20px',
                     display: 'flex',
                     marginRight: '30px'
               }}                  
                   > Spam/delete
                 </button>
               </div>

               <div  onClick={() => {this.handleEndCall({id});}}>
                   <button className="call-endcall" style={{
                     backgroundColor: '#6E6259',
                     color: 'white',
                     padding: '5px 15px',
                     fontSize: '16px',
                     borderRadius: '15px',
                     width: '123px',
             
                     marginTop: '18px',
                     display: 'flex'
                   }} 
                   >
                     Send to C3
                   </button>
                 </div>
                 
         
             </div>
       </div>
   
         </div>
      </div>
         )
      : (  <div className="call-outer-container" style={{
              border: '2px solid #ccc', 
              borderRadius: '8px', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center', 
              height: '43vh', 
              width: '50%', 
              margin: 'auto', 
              marginTop: '15%', 
              padding: '20px', position: 'relative'
              }}> 
              <div style={{marginBottom: '15%', width: '90%'}}>    
                  <table className="call-table" 
                      style={{ 
                      width: '100%', 
                      border: '2px solid #ccc', 
                      borderCollapse: 'collapse',
                      backgroundColor: 'lightgray',         
                    }}>
                    <tbody>
                      <tr>
                      <td className="call-contact-link" style={{ fontWeight: 'bold', textAlign: 'left', backgroundColor: 'lightgray' }}>
                          {contactId ? (
                            <a onClick={() => this.handleOpenCrm(contactId)} className="c3-leadprovider-customer-name">
                              {firstName} {lastName} C3
                            </a>
                          ) : (
                            `${firstName} ${lastName}`
                          )}
                          <br />
                          <span>{fromPhone}</span>
                          <span className="call-datetime-span" style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            marginLeft: '12px',
                            position: 'relative',
                            marginBottom: '10px'
                          }}> {formattedDate} {formattedTime}
                          </span>
                      </td>
                      </tr>          
                    </tbody>
                  </table>      
                  <textarea className="call-textarea"
                    style={{
                      width: '100%',
                      height: '180px',
                      padding: '10px',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                      boxSizing: 'border-box'
                    }}
                    value={this.state.textareaContent}
                    onChange={this.handleTextareaChange}
                    
                  >CALL NOTES FROM OSC*** </textarea>
                  <div className="call-spamdelete-move-toC3-div" style={{position: 'absolute', marginBottom: '10%', display: 'flex', alignItems: 'center'}}>
                    <div onClick={() => {this.handleSpamDelete({id});}}>
                    <button className="call-spamdelete" style={{
                          backgroundColor: '#6E6259',
                          color: 'white',
                          padding: '5px 15px',
                          fontSize: '16px',
                          borderRadius: '15px',
                          marginTop: '20px',
                          display: 'flex',
                          marginRight: '30px'
                    }}                  
                        > Spam/delete
                      </button>
                    </div>

                    <div style={{ marginLeft: '19%' }} onClick={() => {this.handleEndCall({id});}}>
                        <button className="call-endcall" style={{
                          backgroundColor: '#6E6259',
                          color: 'white',
                          padding: '5px 15px',
                          fontSize: '16px',
                          borderRadius: '15px',
                          width: '123px',
                          marginLeft: '38px',
                          marginTop: '18px',
                          display: 'flex'
                        }} 
                        >
                          Send to C3
                        </button>
                      </div>

              
                  </div>
            </div>
        
          </div>
          )
  }
      </>
    );
  }
}

export default Call;
