import MessageBubble from "./MessageBubble";
import React, {PureComponent} from "react";
import PropTypes from "prop-types";


class ConversationsMessages extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {    
        friendlyName: props.friendlyName,
    };   
  }

  handleChange = (author) => {
    this.props.onSelect(author);
  }
  render = () => {
    return (
        <div id="messages">
          <ul>
            { this.props.messages.map(m => {
               if(  m.author.startsWith("+")  )
               {  this.handleChange(m.author, m.friendlyName);
               };

              if (m.author === this.props.identity || m.author.startsWith("+"))
               {  
 
                return <MessageBubble key={m.index} direction="incoming" message={m} author={m.author} friendlyName={m.friendlyName} />;
               }
              else
            {     
                return <MessageBubble key={m.index} direction="outgoing" message={m} author={m.author} friendlyName={m.friendlyName} />;
}
            })
            }
          </ul>
        </div>
    );
  }
}

ConversationsMessages.propTypes = {
  identity: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired

};

export default ConversationsMessages;