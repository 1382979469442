import React from 'react';
import { Layout, Button, Input, Icon, Form, Row, Col, Card } from 'antd';
import { ReactComponent as Logo } from "./assets/KB_Logo.svg";
import Swal from 'sweetalert2';
import "animate.css";
import { min } from 'moment';
import emailjs  from 'emailjs-com';
import {send}  from 'emailjs-com';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import zIndex from '@material-ui/core/styles/zIndex';



const { Content } = Layout;
require('dotenv').config();
export class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            passWord: '',
            userCredentialValid: true,
            isRegistration: false,
            ip: '',
            hash: '',
            loading : false
        };
        this.userNameRef = React.createRef();
        this.passWordRef = React.createRef();       
    }
    redirectToRegister = (e) => {
        Swal.fire({
            title: 'Admin Login',
            html: `<input type="password" id="password" class="swal2-input" placeholder="Password">`,
            confirmButtonText: 'Sign in',
            focusConfirm: false,
            preConfirm: () => {
                //const login = Swal.getPopup().querySelector('#login').value
                const password = Swal.getPopup().querySelector('#password').value
                if (!password) {
                    Swal.showValidationMessage(`Please enter login and password`)
                }
                return {password: password }
            }
        }).then((result) => {
            try {
                if (result.value.password === "") { result.value.password = "unknown" };
                if ( (result.value.password) === process.env.REACT_APP_ADMIN_PASSWORD) {
                        //update the 


                    Swal.fire('Authorized');
                    this.setState({ isRegistration: true }, () => {
                        console.log(this.state.isRegistration);

                        this.props.form.resetFields();
                       
                    });


                }
                else {
                    Swal.fire('UnAuthorized')
                }
                /*  Swal.fire(`
                Login: ${result.value.login}
                Password: ${result.value.password}
                `.trim())
                    */
            }
            catch {

            }
      })
    }

    redirectToLoginPage = (e) => {
        this.setState({ isRegistration: false }, () => {
            console.log(this.state.isRegistration);
        });
        this.setState({ userName: '' }, () => {
            console.log(this.state.userName);
            this.setState({ passWord: '' }, () => {
                console.log(this.state.passWord);
            });
            this.props.form.resetFields();
        });       
    }


 
    createUser = async () => {
        const { userName, passWord, ip } = this.state;
        //alert('Username:' + userName + "\nPassword: " + passWord);                   
        let response = await fetch("http://azwebqa14a.kbhome.com/api/ConversationApi/createUser/",
            {
                headers: {
                "Content-Type": "application/json; charset=UTF-8",
                  //"Origin": '*',
               "Access-Control-Allow-Origin": "*",            
                Accept: "application/json",
                   // "X-Requested-With": "XMLHttpRequest" */
                },
                method: 'POST',
                optionsSuccessStatus: 200,
                body: JSON.stringify({
                    Username: userName,
                    Password: passWord,
                    //CreatedDate:"2021-09-19",
                    RegionId: "14",
                    CommunityId:"00885225",  //00875715
                    Ip: ip,
                    IsActive: true
                })
            }
        )
            .then(response => response.json())
            .then((responseJSON) => {
                alert('New user account has been created!');
                /* this.setState({ userCredentialValid: responseJSON }, () => {                              
                  console.log(this.state.userCredentialValid);        
                 }); */
                //}); 
                /*this.setState({  userCredentialValid: data}, () => {                              
                    console.log(this.state.userCredentialValid);
                });*/

                // })
                // .catch((err) => {
                //  this.setState({ userCredentialValid: false})
                // })  
            });
    }

    handleSubmit = async (e) => {
      

        //e.preventDefault();

      /*    Swal.fire({
            title: "Loading . . .",
            //text: "Please wait",
            imageUrl: "../ajax-loader.gif",
            showConfirmButton: false,
            allowOutsideClick: false
          });   */
          const { form, onSubmit } = this.props;        
         form.validateFields(async (err, values) => {
            //const validUser = await this.getLoginCredentials(values.username,values.password); 
            //alert("User's credentials validated: " +this.state.userCredentialValid);
            localStorage.setItem("userName", values.username);//TODO Need to set this dynamically, with Single Sign On.
            const { username } = values;      
           
         
               
                 /*    Swal.fire({
                       
                        text: "Finished",
                        timer: 500,
                        showConfirmButton: false
                    }); */
                
              onSubmit(username);
            
          
        }); 
                  
    };

      
    async componentDidMount() {
        const response = await fetch('https://geolocation-db.com/json/');
        const data = await response.json();
        this.setState({ ip: data.IPv4 });
    
        this.handleSubmit();
    }


render() {
        const isRegistration = this.state.isRegistration;
        const isLoading = this.state.isLoading;
        const { getFieldDecorator } = this.props.form;
        const usernameFieldDecorator = getFieldDecorator('username', {
            rules: [{ required: true, min: 8, message: 'Please input your username!' }],
        });
        const passwordFieldDecorator = getFieldDecorator('password', {
            rules: [{ required: true, min: 8, message: 'Please input your password!' }],
        });

        const style = {
            position: 'absolute',
            marginLeft: '550px',  
            bottom: '300px',
            zIndex: 9
        }

        return (     
        <Layout>   
         
                <div>
                    {isRegistration === false ? (<div>
                        <Content style={{ height: '100vh', backgroundColor: 'lightgrey' }} >
                            <Row type="flex" justify="space-around" align="middle" style={{ height: '100%' }}>
                                <Col span={12} offset={6}>

                                    <Card style={{ maxWidth: '404px', borderRadius: '8px' }} title="Login" >

                                        <Row type="flex" justify="center" align="middle" style={{ marginBottom: '30px' }}>
                                            <Logo />
                                        </Row>
                                        <Form onSubmit={this.handleSubmit} id="login-form">
                                            <Form.Item >
                                                {usernameFieldDecorator(
                                                    <Input style={{ fontWeight: '600' }}
                                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        placeholder="Username"
                                                        id="Username"
                                                    />,
                                                )}
                                            </Form.Item>
                                            <Form.Item>
                                                {passwordFieldDecorator(
                                                    <Input type="password" style={{ fontWeight: '600' }}
                                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        placeholder="Password"
                                                        id="Password"
                                                    />,
                                                )}
                                            </Form.Item>


                                            <Form.Item>
                                                <Button block type="primary" htmlType="submit">
                                                    Submit
                                                </Button>
                                            </Form.Item>

                                            
                                            <Form.Item>
                                                <a style={{ paddingLeft: '5px', color: 'blue' }} >Forgot Password?</a><span style={{paddingLeft: '30px'}}>   For Admin :<a style={{ paddingLeft: '5px', color: 'blue' }} onClick={() => this.redirectToRegister(this)}>Set up new account</a></span>
                                            </Form.Item>
  
                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                        </Content>
                    </div>) : null}
                </div>

      

                <div>
                    {isRegistration === true ? (<div>
                        <Content style={{ height: '100vh', backgroundColor: 'lightgrey' }} >
                            <Row type="flex" justify="space-around" align="middle" style={{ height: '100%' }}>
                                <Col span={12} offset={6}>
                                    <Card style={{ maxWidth: '404px', borderRadius: '8px' }} title="Registration">
                                        <Row type="flex" justify="center" align="middle" style={{ marginBottom: '30px' }}>
                                            <Logo />
                                        </Row>

                                        <Form onSubmit={this.handleSubmit} id="register-form">
                                            <Form.Item >
                                                {usernameFieldDecorator(
                                                    <Input style={{ fontWeight: '600' }}
                                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        placeholder="Username"
                                                        id="username_registration"
                                                        ref={this.userNameRef}
                                                    //value={this.state.userName}
                                                    //onChange={this.handleChangeUsername.bind(this)} 
                                                    />,
                                                )}
                                            </Form.Item>

                                            <Form.Item>
                                                {passwordFieldDecorator(
                                                    <Input type="text" style={{ fontWeight: '600' }}
                                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                        placeholder="Password"
                                                        id="password_registration"
                                                        ref={this.passWordRef}
                                                    //value={this.state.passWord}
                                                    //onChange={this.handleChangePassword.bind(this)} 
                                                    />,
                                                )}

                                            </Form.Item>

                                            <Form.Item>
                                                <Button block type="primary" onClick={() => this.resgistrationSubmit()} >
                                                    Register
                                                </Button>
                                            </Form.Item>
                                            <Form.Item>
                                                Back to<a style={{ paddingLeft: '5px', color: 'blue' }} onClick={() => this.redirectToLoginPage(this)}>Login</a>
                                            </Form.Item>

                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                        </Content>
                    </div>) : null}
                </div>

              {/*   <Form onSubmit={this.onSubmit}>
                    <Input
                        type='text'
                        name='from_name'
                        placeholder='from name'
                        value={"tai"}
                        onChange={this.handleChange}
                    />
                    <Input
                      type='text'
                      name='to_name'
                      placeholder='to name'
                      value={"tai"}
                      onChange={this.handleChange}
                    />
                    <Input
                        type='text'
                        name='message'
                        placeholder='Your message'
                        value={"test message"}
                        onChange={this.handleChange}
                    />
                    <Input
                         type='text'
                         name='reply_to'
                         placeholder='Your email'
                         value={"your email"}
                         onChange={this.handleChange}
                    />
                    <Button type='submit' htmlType="submit">Submit</Button>
                </Form>  */}
            </Layout>
        )
    }
}

export default Form.create({ name: 'login' })(LoginPage);
