import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";

import Button from "react-bootstrap/Button";


/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {

            console.log("Instance: "+instance);
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        }

        else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    const title_style = {
        color: 'black', 
      
        fontSize: '30px', 
        fontWeight: '600', 
        paddingTop: '6px', 
  

    }
    const button_style = {

        
    }

    return (

        <>
             <div style={{textAlign: 'center'}}>
             <div style={title_style}>KB Home Text and Call Notifications</div>

                <div className="mb-2" style={{paddingTop:'20px'}}>
                    <Button size="lg" onClick={() => handleLogin("popup")} 
                    style={{width: '200px', color:'black', fontWeight: 'bold', backgroundColor: '#ffd133'}}>
                        Sign In
                    </Button>{' '}
                </div>
                </div>

   
            




        </>
    )
}