import FormComponent from './FormComponent';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
class ModalComponent extends Component {
    constructor(props){
        super(props)
        this.state={
            modalShowToggle: props.show
        }
    }
    render(){
        if(this.state.modalShowToggle){
            return(
                <div> 
                    <div className='ModalContainer'>
                        <FormComponent />
                    </div>
                </div>
            )
        } else {
           
        }
    }
  }

  export default ModalComponent;

