import React from "react";
import { useMsal } from "@azure/msal-react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";

import Button from "react-bootstrap/Button";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }
    return (
        <>
   {/*      <div className="mb-2">
        <Button variant="primary" size="lg" onClick={() => handleLogout"popup")}>
          Sign Out
        </Button>{' '}
      
        <Button variant="secondary" onClick={() => handleLogout("popup")} className="ml-auto" drop="left" title="Sign Out" style={{paddingLeft: "78%", position: "absolute", paddingTop: "170px", zIndex: "999"}}>
           

        </Button>
        </div> */}


<div className="mb-2" style={{marginLeft: '77%', position:'absolute', marginTop: '10%', zIndex: '99'}}>
   {/*  <Button variant="outline-danger" size="md" onClick={() => handleLogout("popup")} >
      Sign Out
    </Button>{' '} */}
    </div>
        </>
    )
}